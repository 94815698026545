export const environment = {
  production: false,
  reduxToolsEnabled: true,
  reduxMaxAge: 100,
  brandCode: 'td',
  appName: 'console-td',
  defaultLocaleId: 'en-CA',
  // APIS -----------------------------------------------------------
  configApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/configs/',
  developersApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/developers/',
  tenantApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/tenants/',
  inboxApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/inbox/',
  integrationApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/integrations/',
  networksApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/networks/',
  recordsApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/records/',
  gatewayApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/',
  subscriptionApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/subscriptions/',
  userApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/users/',
  plansApi: 'https://td-api-sbx.sdrwsbx.co/api/v1/planrequests/',
  maxElementsPerPage: 50,
  // AUTH0 DEFAULT-----------------------------------------------------------
  audience: 'https://user-api-sbx.sidedrawersbx.com',
  clientID: 'console-sbx',
  connection: 'Username-Password-Authentication',
  domain: 'https://auth-pat.digitalvault.td.com/realms/tddv-sbx',
  redirectUri: '/auth/authorize',
  scope: 'profile email roles web-origins',
  entityAppRoleName: 'consoleApp',
  // CDN -----------------------------------------------------------
  cdn: 'https://cdn-pat.digitalvault.td.com/',
  // Versions --------------------------------------------------
  version: '1.0.1',
  dictionaryVersion: 'console_20210501',
  // Token & Session
  inactivityTime: 900,
  inactivityDialogTime: 120,
  refreshTokenTime: 300,
  // Intercom -----------------------------------------------
  intercomAppId: 'eufoidmt',
  intercomHashUrl: 'https://intercom.sdrwsbx.co/hash',
  // Redirects ----------------------------------------------
  myUrl: 'https://pat.digitalvault.td.com',
  // PDFTron
  pdfTronLicense: 'SideDrawer Inc :OEM:SideDrawer Web Application::B+:AMS(20270427):4064CCFCE6CFC55AE048B353187F610F600DD26EE0FD704D292A020EF652FA31F5C7',
};
